export const historyGridItemFrag = `
    id
    entityId
    entityName
    authorId
    authorName
    data
    oldData
    changedFields
    timestamp
    comment
    action
`;
